import React, {useState, useEffect} from  'react';
import axios from "axios";


const Book =  () => {
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [ebook, setEbook] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages/19`)
            .then((res) => {
                setContent(res.data.content.rendered);
                setEbook(res.data.acf.ebook_link);

                axios.get(res.data._links['wp:featuredmedia'][0].href).then((res) =>{
                    setImage(res.data.guid.rendered);
                })
            });
    }, []);

    return (
        <div className="ebook">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-5 offset-md-1 text-center">
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    <a href={ebook} target={'_blank'} className="ebook__download d-none d-md-block">E-Book herunterladen</a>
                </div>
                <div className="class=col-12 col-md-5 offset-md-1 text-center">
                    <img src={image} className="ebook__logo" alt="logo" />
                    <a href={ebook} target={'_blank'}  className="ebook__download d-md-none d-lg-none">E-Book herunterladen</a>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Book;