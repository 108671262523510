import React from  'react';
import logo from '../logo.svg';
const Header =  () => {
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={logo} className="header__logo" alt="logo" />
                    </div>
                </div>
            </div>

        </header>
    );
};

export default Header;