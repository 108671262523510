import React, {useState} from  'react';
import axios from "axios";
import {validateField} from '../ValidateForms';

const ContactForm =  () => {
    const [product, setProduct] = useState('');
    const [target, setTarget] = useState('');
    const [persons, setPersons] = useState('');
    const [find, setFind] = useState('');
    const [others, setOthers] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError , setEmailError] = useState('');
    const [emailSent , setEmailSent] = useState(false);

    const submitContactForm = async (e) => {
        const validName = validateField('name', 'text', name);
        const validEmail = validateField('email', 'email', email);
        setNameError(validName);
        setEmailError(validEmail);

        const headers = {
            'Content-Type': 'application/json',
        };

        const formData =  {
            product: product,
            name: name,
            target: target,
            persons: persons,
            find: find,
            others: others,
            company: company,
            phone: phone,
            email: email,
        };

        if (validName.isValid && validEmail.isValid){
            const res = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/wp-json/custom/contact-form`,
                data: formData,
                headers: headers
            });

            if(res.data.toLowerCase() === 'success'){
                setProduct(false);
                setTarget(false);
                setPersons(false);
                setFind(false);
                setOthers(false);
                setName('');
                setCompany('');
                setPhone('');
                setEmail('');
            }

            setEmailSent(true);
        }
    };

    return (
       <div className="contact">
           <div className="container">
               <div className="row">
                   <div className="offset-md-1 col-md-10 offset-md-1">
                           <div className="contact__title">
                               <h2>Welches Ziel wollen Sie mit einem Recommender System erreichen?</h2>
                           </div>
                           <div className="contact__checkbox">
                               <input onClick={(e) => setProduct(e.target.checked)} checked={product} type="checkbox" name={"first"} id={"first_chkb"}/>
                               <label htmlFor="first_chkb">Persönliche Produkt- und Service-Empfehlungen machen</label>
                           </div>
                           <div className="contact__checkbox">
                               <input onClick={(e) => setTarget(e.target.checked)} checked={target} type="checkbox" name={"second"} id={"second_chkb"}/>
                               <label htmlFor="second_chkb">Gezielte Kundensegmentierung anhand des Kaufverhaltens</label>
                           </div>
                           <div className="contact__checkbox">
                               <input onClick={(e) => setPersons(e.target.checked)} checked={persons} type="checkbox" name={"third"} id={"third_chkb"}/>
                               <label htmlFor="third_chkb">Personas mit Interessen verfeinern um gezielteres Marketing zu machen.</label>
                           </div>
                           <div className="contact__checkbox">
                               <input onClick={(e) => setFind(e.target.checked)} checked={find} type="checkbox" name={"fourth"} id={"fourth_chkb"}/>
                               <label htmlFor="fourth_chkb">Herausfinden, ob mit meinen Daten ein sinnvolles Recommender System gebaut werden kann</label>
                           </div>
                           <div className="contact__checkbox mb-5">
                               <input onClick={(e) => setOthers(e.target.checked)} checked={others} type="checkbox" name={"fifth"} id={"fifth_chkb"}/>
                               <label htmlFor="fifth_chkb">Etwas anderes.</label>
                           </div>
                           <div className="form-group required">
                               <label htmlFor="name">IHR NAME *</label>
                               <input onChange={(e) => setName(e.target.value)} type="text" value={name} className={nameError.isValid === false ? 'form-control invalid' : 'form-control'} id="name"/>
                               <small id="nameHelp" className={nameError.isValid === false ? 'form-text text-muted error-message' : 'd-none'}>{nameError.message}</small>
                           </div>
                           <div className="form-group">
                               <label htmlFor="firma">IHRE FIRMA</label>
                               <input onChange={(e) => setCompany(e.target.value)} value={company} type="text" className="form-control" id="firma"/>
                           </div>
                           <div className="form-group">
                               <label htmlFor="tel">IHRE TELEFONNUMMER</label>
                               <input onChange={(e) => setPhone(e.target.value)} value={phone} id="tel" type="text" className="form-control"/>
                               {/*<small id="telHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>*/}
                           </div>
                           <div className="form-group">
                               <label htmlFor="email">IHRE E-MAIL *</label>
                               <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" className={emailError.isValid === false ? 'form-control invalid' : 'form-control'}/>
                               <small id="nameHelp" className={emailError.isValid === false ? 'form-text text-muted error-message' : 'd-none'}>{emailError.message}</small>
                           </div>
                       <div className="form-group contact__send">
                           <button onClick={submitContactForm} type={'submit'} className={emailError.isValid && nameError.isValid === true ? 'form-text contact__send__false' : 'contact__send__success'}>Senden</button>
                           <small id="nameHelp" className={emailSent === true ? 'form-text text-muted success' : 'd-none'}>Vielen Dank für Ihre Kontaktaufnahme. Wir setzen uns schnellstmöglich mit Ihnen in Kontakt.</small>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    );
};

export default ContactForm;