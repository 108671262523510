import React from 'react';

const Loading =  () => {
    return (
        <div className="loading">
            <preloader>
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </preloader>
        </div>
    );
};

export default Loading;
