export const validateField = (name, type, value) => {

    switch(type) {
        case 'text':
            if(!value || value === '' || value === undefined){
                return {
                    name: name,
                    type: type,
                    value: value,
                    isValid: false,
                    message: `«Dieses Feld ist ein Pflichtfeld.»`
                }
            }

            return {
                name: name,
                type: type,
                value: value,
                isValid: true,
                message: `The field ${name} is valid.`
            };

        case 'email':
            if(!value || value === '' || value === undefined){
                return {
                    name: name,
                    type: type,
                    value: value,
                    isValid: false,
                    message: `«Dieses Feld ist ein Pflichtfeld.»`
                }
            }

            if(value){
                const reg = /\S+@\S+\.\S+/;

                if (reg.test(value) === false){
                    return {
                        name: name,
                        type: type,
                        value: value,
                        isValid: false,
                        message: `«Bitte geben Sie eine gültige E-Mail Adresse ein.»`
                    }
                }

                return {
                    name: name,
                    type: type,
                    value: value,
                    isValid: true,
                    message: `The field ${name} is valid.`
                };
            }

            break;
        default:
    }
};