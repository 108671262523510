import React from  'react';
import Book from './blocks/Book';
import Referenzen from  './blocks/Referenzen';
import Slider from "./blocks/Slider";
import ContactForm from "./blocks/ContactForm";

const Content =  () => {
    return (
        <React.Fragment>
            <Slider/>
            <Referenzen/>
            <ContactForm/>
            <Book/>
        </React.Fragment>
    );
};

export default Content;