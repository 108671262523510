import React, {useEffect, useState} from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import axios from "axios";
import Loading from "./Loading";
const Slider =  () => {

    let direction = 'vertical';
    let wheelScroll =  true;
    let isMobileDevice = false;

    if (window.innerWidth <= 480){
        direction = 'horizontal';
        wheelScroll = false;
        isMobileDevice = true;
    }

    const [sliders, setSliders] = useState([]);
    const [isLoading, setLoading] = useState('true');
    const params = {
        direction: direction,
        autoHeight: true,
        setWrapperSize: true,
        slidesPerView: 1,
        spaceBetween: 60,
        rebuildOnUpdate: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets'
        },
        mousewheel: {
            enabled: wheelScroll,
            releaseOnEdges: true,
            sensitivity: 0
        },
        mousewheelControl: true,
        mousewheelReleaseOnEdges: true,
        allowTouchMove: true,
        touchReleaseOnEdges: true,
    };


    const getSliders = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/custom/sliders`);
        if (res) {
            setSliders(res.data);
        }
        setTimeout(() => setLoading(false), 1500);
    };

    useEffect(  () => {
        getSliders();
    }, []);
    if(isLoading){
        return <Loading/>;
    }
    else {
        return (
            <div className={'container slider'}>
                <div className={'row'}>
                    <div className={'col-12'}>

                        {isMobileDevice ? (
                            sliders.map((slide, index) => {
                                return (
                                    <div>
                                        <div className={index % 2 === 1 ? 'slider__slide d-sm-block d-md-flex flex-row-reverse' : 'slider__slide d-sm-block d-md-flex flex-row'}>
                                            <div className={'slider__slide__left flex-column w-50'}>
                                                <h2 className={'slider__slide__left__title'}>{slide.title}</h2>
                                                <p className={'slider__slide__left__content'}>{slide.content}</p>
                                            </div>

                                            <div className={'slider__slide__right flex-column w-50'}>
                                                <figure className={'slider__slide__right__image'}>
                                                    <img src={slide.image} alt={slide.title} />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <Swiper {...params}>
                                {
                                    sliders.map((slide, index) => {
                                        const leftPadding = index % 2 === 1 ? '' : '90px';
                                        const rightPadding = index % 2 === 1 ? '90px' : '';

                                        return (
                                            <div>
                                                <div className={index % 2 === 1 ? 'slider__slide d-sm-block d-md-flex flex-row-reverse' : 'slider__slide d-sm-block d-md-flex flex-row'}>
                                                    <div style={{paddingLeft: leftPadding}} className={'slider__slide__left flex-column w-50'}>
                                                        <h2 className={'slider__slide__left__title'}>{slide.title}</h2>
                                                        <p className={'slider__slide__left__content'}>{slide.content}</p>
                                                    </div>

                                                    <div style={{paddingLeft: rightPadding}} className={'slider__slide__right flex-column w-50'}>
                                                        <figure className={'slider__slide__right__image'}>
                                                            <img src={slide.image} alt={slide.title} />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Swiper>
                        )}

                    </div>
                </div>
            </div>
        );
    }

};

export default Slider;