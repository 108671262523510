import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

function App() {
  return (
      <React.Fragment>
        <Header/>
          <Content/>
        <Footer/>
      </React.Fragment>
  );
}

export default App;
