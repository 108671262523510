import React, {useState, useEffect} from  'react';
import heart from '../heart.svg';
import axios from "axios";
const Footer =  () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages/17`)
            .then((res) => {
                setContent(res.data.content.rendered);
            });
    }, []);

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-11 offset-md-1" dangerouslySetInnerHTML={{__html: content}} />
                </div>

                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1 text-center">
                        <div>
                            <p className="footer__copyright">Made with <img src={heart} alt=""/> by Jaywalker Digital</p>
                            <p className="footer__copyright">© {(new Date().getFullYear())}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;