import React, {useState, useEffect} from  'react';
import axios from "axios";

const Referenzen =  () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages/84`)
            .then((res) => {
                setContent(res.data.content.rendered);
            });
    }, []);


    return (
        <div className={'referenzen d-none'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-10 offset-md-1'}>
                        <div dangerouslySetInnerHTML={{__html: content}}  />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Referenzen;